<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="订单信息" :bordered='false' >
      <span slot='extra'>
        <template>
<!--          自提的 v-if=" order.status == 'delivering'"-->
          <a-space style="margin-left: 20px" >
                  <div>状态：<span style="font-size: large;color: #00A0E9">
                      <template v-if="order.checkStatus=='0'">未核销</template>
                      <template v-if="order.checkStatus=='1'">已核销</template>
                      <template v-if="order.checkStatus=='2'">已退单</template>
                  </span></div>

          </a-space>
        </template>
      </span>

      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='购买人姓名'>{{order.memberName}}</a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{ order.createTime }}</a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>{{ order.spreaderName }}</a-descriptions-item>
        <a-descriptions-item label='推荐人手机号码'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='收款人'>
            <span v-if="order.scene=='1'">{{ order.spreaderName }}</span>
          <span v-if="order.scene=='2'">{{ order.merchantBankAccountName }}</span>
        </a-descriptions-item>
        <a-descriptions-item label='会议时间'>{{ order.startDate }}</a-descriptions-item>

        <template v-if="order.checkStatus=='2'">
          <a-descriptions-item label='退单方式' :span="4" >
            <span v-if="order.returnType=='1'">原路退回</span>
            <span v-else-if="order.returnType=='2'">线下退款</span>
          </a-descriptions-item>
        </template>

      </a-descriptions>
    </a-card>

    <a-card title="核销信息" :bordered='false' style="margin-top: 20px;">
      <a-descriptions :column="1">
        <template  v-for="(item, index) in order.rewardList" >
          <a-descriptions-item  :key="index" >{{item.userTypeName}}:   {{item.userName||' 未知 :'}} {{item.integralTypeName}} {{item.quantity}}</a-descriptions-item>

        </template>
      </a-descriptions>

    </a-card>


  </page-header-wrapper>
</template>

<script>
import {STable, Ellipsis} from '@/components'
import {getMember, getMerchantMemberByMobile} from '@/api/member'
import {
  getMemberOrder,
  putAuditOrder,
  getAuditRecord,
  getMemberCash,
  refund,
  queryOrderDetail499
} from '@/api/member-order'
import {getFuZeRen, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from 'moment'

const formatType = 'YYYY-MM-DD HH:mm';

const columns1 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后可用积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

export default {
  name: 'AuditOrderEdit',

  data() {
    return {
      guiShuName:'',//归属名称
      ext4:0,//积分合计
      loading: false,
      profile: {},
      merchantProfile:{},
      order: {},
      record: [],
      font_class: "",
      yingFu: 0,
      formatType,
      columns1,
      // dateArr: [baseStart, baseEnd],
      // deliver1: { visible: false,
      //   form: {
      //     // start: baseStart,
      //     // end: baseEnd,
      //   }, loading: false
      // },
      // deliver2: { visible: false,
      //   form: {
      //     backAmount:0,
      //     backType:1,
      //   },
      //   loading: false,
      //   rules:{
      //     backType: [
      //       { required: true, message: '请选择退款方式', trigger: 'change' },
      //     ],
      //     backAmount:[
      //       { required: true, message: '请输入数量', trigger: 'change' },
      //     ],
      //   }
      // },
      mType:1,
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        let gsName = '';
        if(this.mType==='1'){
          let _profile = this.profile
          parameter.member = _profile.id;
          gsName= _profile.nickname
        }else{
          let _merchantProfile = this.merchantProfile
          parameter.member = _merchantProfile.id;
          gsName = _merchantProfile.masterName
        }
        this.guiShuName = gsName;

        let params = Object.assign(parameter,this.queryParam, this.deliver1.form);
        // debugger
        return getMemberCash(params).then(datum => {
          this.ext4 = datum.ext4;
            // if(datum.records && datum.records.length>0){
            //   this.guiShuName = datum.records[0].userName
            // }
            return datum
          })
      },


    }
  },
  components: {STable, Ellipsis},
  computed: {
    // id () {
    //   return this.$route.query.id;
    // },
  },
  activated() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id );
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id );
  },
  methods: {
    handleInitial(id) {
      let params = {
        id: id
      }
      queryOrderDetail499(params).then(res => {
          // debugger
          this.order = res;
      });
    }

  }
}
</script>
<style scoped>
.font-red {
  color: #E62734 !important;
}


</style>
